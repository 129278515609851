<template lang="pug">
  div
    v-row
      v-col(cols="12")
        v-autocomplete(
          :items="stockHasFeedDetailList"
          label="Stock"
          v-model="stock"
          item-value="id"
          item-text="description"
          return-object
        )
          template(v-slot:selection="{ item }")
            small.blue-grey--text.ml-2 {{ item.description }}
          template(v-slot:item="{ item }")
            span.primary--text {{ item.id }}
            small.blue-grey--text.ml-2 {{ item.description }}
      v-col(cols="12")
        v-autocomplete(
          label="Unit"
          v-model="unit"
          :items="units"
        )
      v-col(cols="6")
        v-text-field(
          label="Cost"
          type="number"
          :value="cost"
          readonly
        )
      v-col(cols="6")
        v-text-field(
          label="Quantity"
          type="number"
          v-model.number="quantity"
        )
      v-col(cols="12")
        v-btn(
          color="green"
          block
          dark
          @click="addToDetails"
        )
          span Add
</template>
<script>
import stockRepository from '@/repositories/stock.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [stockHasFeedDetailVars, stockHasFeedDetailVarNames] = requestVars({ identifier: 'stock-has-feed-detail' })
const stockHasFeedDetailHandler = new VueRequestHandler(null, stockHasFeedDetailVarNames)

const inputVars = () => ({
  unit: null,
  quantity: 1,
})

export default {
  name: 'CreatePurchaseRequestDetail',
  props: ['value'],
  data () {
    return {
      ...inputVars(),
      ...stockHasFeedDetailVars,
      stock: null,
    }
  },
  computed: {
    stockId () {
      return this.stock ? this.stock.id : null
    },
    cost () {
      if (!this.stock) return null
      return this.stock.units ? this.stock.units[this.unit].cost : null
    },
    units () {
      return this.stock ? Object.keys(this.stock.units) : []
    },
  },
  created () {
    this.getStocks()
  },
  watch: {
    units (list) {
      if (!list.length) {
        this.unit = null
        return
      }
      const [first] = list
      this.unit = first
    },
  },
  methods: {
    addToDetails () {
      this.value.push(this.getInputData())
    },
    getInputData () {
      return Object.keys(inputVars())
        .concat(['stockId', 'cost'])
        .reduce((result, key) => {
          result[key] = this[key]
          return result
        }, {})
    },
    getStocks () {
      const handler = stockHasFeedDetailHandler
      const repository = stockRepository.hasFeedDetail
      handler.setVue(this)
      handler.execute(repository)
    },
  },
}
</script>